import React, { createContext, useEffect, useState } from "react"
import { axiosInstance } from "../api/instance"
import { navigate } from "gatsby"
import { route } from "../constants/route"
import useClient from "../hook/useClient"
import { Spinner } from "react-bootstrap"

export const AppContext = createContext("")

const AppContextProvider = ({ children }) => {
  const router = navigate
  const [isInitialized, setIsInitialized] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)
  const [profileInfo, setProfileInfo] = useState(null)

  const logout = () => {
    delete axiosInstance.defaults.headers.common.Authorization
    localStorage.removeItem("accessTokenNolimCloud")
    localStorage.removeItem("refreshTokenNolimCloud")
    localStorage.removeItem("profileInfo")
    setProfileInfo(null)
    navigate(route.home)
  }
  const isClient = useClient()

  useEffect(() => {
    if (JSON.stringify(profileInfo) !== localStorage.getItem("profileInfo")) {
      setProfileInfo(JSON.parse(localStorage.getItem("profileInfo")))
    }
    if (!isInitialized) {
      setIsInitialized(true)
    }
    if (isClient && !localStorage.getItem("profileInfo")) {
      if (
        isInitialized &&
        window.location.pathname.length > 1 &&
        !window.location.pathname.includes(route.term) &&
        !window.location.pathname.includes(route.docs) &&
        !window.location.pathname.includes(route.politics) &&
        !window.location.pathname.includes("404") &&
        !window.location.pathname.includes("auth")
      ) {
        router(route.login)
      }
    }
  }, [isClient])

  const value = {
    logout,
    router,
    requestLoading,
    setRequestLoading,
    profileInfo,
    setProfileInfo,
    setIsInitialized,
    isInitialized,
  }

  return (
    <AppContext.Provider value={value}>
      {children}
      {isInitialized ? (
        <></>
      ) : (
        <div
          className={
            "position-fixed min-vh-100 d-flex align-items-center bg-light justify-content-center  "
          }
          style={{
            width: "100vw",
            height: "100vh",
            zIndex: 99999999999999999999,
          }}
        >
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </AppContext.Provider>
  )
}
export default AppContextProvider
