import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const StaticDataContext = createContext("")

const StaticDataContextProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          fields {
            slug
          }
        }
      }
      fetch {
        tariff {
          page
          pages
          total
          items {
            days
            id
            integrations
            price
            title
          }
        }
        dbTypes {
          id
          record_limits
          title
          url
          connection_parameters_schema {
            _schema
            additionalProperties
            required
            type
            properties {
              database_id {
                type
              }
              email {
                format
                type
              }
              token {
                type
              }
              url {
                format
                pattern
                type
              }
            }
          }
          connection_parameter_labels {
            database_id
            email
            token
            url
          }
        }
      }
    }
  `)

  /*dbTypes {
          id
          record_limits
          title
          url
          connection_parameters_schema {
            _schema
            additionalProperties
            required
            type
            properties {
              domain {
                type
              }
              login {
                type
              }
              password {
                type
              }
            }
          }
          connection_parameter_labels {
            domain
            login
            password
          }
        } */
  const freeTariff = {
    days: 7,
    id: 0,
    integrations: 10,
    price: 0,
    title: "Подписка на 7 дней",
  }
  const tariffs = data.fetch.tariff.items
    .sort((a, b) => a.days - b.days)
    .filter(x => x.id != 4)
  const dbTypes = data.fetch.dbTypes
  const dbDocUrls = data.allMarkdownRemark.nodes.map(el =>
    el.fields.slug.replace(/\//g, "")
  )

  const value = {
    dbTypes,
    tariffs,
    dbDocUrls,
  }

  return (
    <StaticDataContext.Provider value={value}>
      {children}
    </StaticDataContext.Provider>
  )
}
export default StaticDataContextProvider
